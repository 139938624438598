import React, { Component } from 'react';
import IMAGES from '../assets';
import { Navbar, Sidebar, Footer } from '../components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHero from './PageHero';
import Heading from "../components/Heading";
import { faChevronCircleRight, faMinus, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ReactPageScroller from 'react-page-scroller';


class Home extends Component {
  render() {
    return (
      <>
      <Navbar />
      <Sidebar /> 
      <div class="row">
        <div className="curve" align="center" >
          <div className="row">
            <div className="col-md-12">
              <img src={IMAGES.change} className="img-fluid animated zoomInDown" />
            </div>
            <div class="col-12">
              <h6 class="display-4 white  animated s1 fadeInUp"><strong>Simplifying the process of Creating </strong>
                <strong><Link to="/website" class="white" style={{color:"white"}}><u>Website</u></Link></strong>
              </h6>
            </div>
            <div class="col-12">
              <div class="laptop pt-4 animated s3 pulse">
                <Link to="/website" class="btn btn-lg btn-warning" role="button"><em>Get your Website</em></Link>
                <p class="initialism m-2 lead ">
                  <FontAwesomeIcon icon={faChevronDown} className='d1 animated infinite slideInDown' />
                  <div className='clear'></div>
                  <small>Scroll down to explore.</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-12  body-bgcolor" align="center">
        <div className='my-2'> <div class="mt-1 blue wow animated fadeInDown">
            <FontAwesomeIcon icon={faMinus} /></div>
          <h1 class="display-3 wow animated fadeInUp">Build <strong class="blue">Brand</strong> not just <strong class="blue">Website.</strong></h1>
          <p class="mt-1 wow animated fadeInUp">
            Building Website is simple as filling a form.
          </p>
          <Link to="/Signup" class="btn btn-lg btn-warning box-shadow mt-1 wow animated bounceIn" role="button">
            <em><strong>Try it Yourself &nbsp;
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </strong></em>
           </Link>
         </div> 
          <div className='clear'></div>
          <div className='container section-btop'>

<div className='my-2'>  <Heading heading="How it" catchy=" works" info="Be there in 3 simple stages and it's done." /></div>
<span><img src={IMAGES.works} className="img-fluid animated bounceIn" /></span>
<div class="row features mt-3">
              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.formhandling} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow  animated fadeInUp">Consultation</h2>
                <p class="mt-1 wow animated  fadeInUp">We listen to your needs and goals to understand what you're looking for in a website.</p>
              </div>
              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.responsive} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow animated fadeInUp ">Development</h2>
                <p class="mt-1 wow animated fadeInUp ">Every Design is a well crafted which fits your brand and needs, and develop a high-quality website.
                </p>

              </div>
              <div className='col-md-4 mb-1'>
                <span><img src={IMAGES.hosting} className="img-fluid animated bounceIn" /></span>
                <h2 class="display-4 mt-1 wow animated fadeInUp ">Testing and Launch</h2>
                <p class="mt-1 wow animated fadeInUp ">We test the website to ensure it's functioning properly and launch it to the public.
                .</p>

              </div>

           
            </div>
<div className='homeblocks mt-3'>
<div className='my-5'> <div class="mt-1 blue wow animated fadeInDown">
            <FontAwesomeIcon icon={faMinus} /></div>
          <h1 class="display-3 wow animated fadeInUp">Your <strong class="blue">Choice</strong> of <strong class="blue">Design.</strong></h1>
          <p class="mt-1 wow animated fadeInUp">
            Crafting designs which matches with international standards.
          </p>
          <Link to="/Website" class="btn btn-lg btn-warning box-shadow mt-1 wow animated bounceIn" role="button">
            <em><strong>Let's Go &nbsp;
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </strong></em>
           </Link>
         </div> 

</div>

</div>
          <div className='container section-btop'>

<div className='my-2'>  <Heading heading="Doesn't get's in browser" catchy=" issues" info="Our websites are like water, it flows." /></div>
<span><img src={IMAGES.responsiveconflict} className="img-fluid animated bounceIn" /></span>
</div>
          


   
        <div className='container section-btop'>

<div className='my-2'>  <Heading heading="About" catchy=" us" /></div>
<div class="row">
          <div className='col-md-12 mb-1'>
          We are a team of experienced and talented web designers dedicated to delivering high-quality and innovative web solutions to our clients. 
          With years of experience in the industry, 
          we have a deep understanding of what it takes 
          to create a website that meets the unique needs of each business.
<br/><br/>
          <strong>Our mission is simple </strong>
: to help businesses and individuals succeed online. We believe that a well-designed website can be a powerful tool 
for achieving success, and we are dedicated to helping our clients reach their goals through our expert website design services.
            </div>
            </div>
</div>

        </div>
        
      </div>
      
      </>
    )
  }
}

export default Home;