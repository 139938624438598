import React, { Component } from 'react';
import IMAGES from '../assets'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'


class Footer extends Component {
  render() {
    return (
      <div className='wrapper'>
          
       {/* <div className="br-blue">
          <div class="container">
           <div class="footer-links wid100" style="background:none !important;">
            
            <h1 class="display-4 wow  fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">Subscribe to our newsletter</h1>
            <div class="footer-heading">
              <input type="text" class="sub-input" placeholder="Email " aria-label="Email Address" aria-describedby="basic-addon2" />
              <button class="btn btn-warning sub-button" type="button">Subscribe</button>
            </div>
          </div>
          </div> 
          </div>
        </div>*/}
                    

        <div className='container footer-bg'>
          <div class="row">
 
              <div class="col-md-5">
                <Link to='http://www.ovalstech.in/'><img src={ IMAGES.footLogo } alt='websitea1-footer-logo' className='my-1' /></Link>
                <div class="clear"></div>
              </div>
              <div class="col-md-7">
                <div class="ovals my-1 pull-right">
                <Link to='/support'>Help & Support</Link> |   <Link to='/terms' >Terms</Link> | <Link to='/privacy'>Privacy Policy</Link> | <Link to='/contact'>Contact</Link>
                  <h5 class="footer-copy mt-1 align-right">&copy; Copyright {new Date().getFullYear()} Website<medium className='text-success'>On</medium></h5>
                </div>
              </div>
  
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;