
export const Links = [
    {
      id: 1,
      text: 'Home',
      url: '/',
    },
    {
      id: 8,
      text: 'About me',
      url: '/AboutMe',
    },
    {
      id: 2,
      text: 'Designs',
      url: '/website',
    },
   
   {
      id: 4,
      text: 'Plans',
      url: '/WebsitePlans',
    }, 
  
    {
      id: 5,
      text: 'Contact',
      url: '/contact',
    },

   {
     id: 6,
    text: 'Login',
   url: '/signIn',
 },
 
    {
    id: 7,
    text: 'Signup',
    url: '/signUp',
    },
   
   
  
    
        
      

  ]
